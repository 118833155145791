import { Vue, Component, } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';
import { ScreenText } from '@/lang/ScreenText';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { getaccountbyid } from '@/services/userService/users-api';

@Component({
  components: {

  }
})
export default class AccountDataTabs extends Vue {
  private readonly screenText = new ScreenText();
  private currentUserRole: number = +(
    APP_UTILITIES.getCookie('highest_role') || APP_CONST.ZERO
  );
  private readonly allowedRolesForPayments = [
    APP_CONST.ROLES.BX_SUPER_ADMIN,
    APP_CONST.ROLES.ACCOUNT_ADMIN
  ];
  // TODO (REFACTOR): This will need to be reworked when role checks are centralized (PROD-46)
  readonly canAccessPayments = this.allowedRolesForPayments.some(r => r === this.currentUserRole);

  public isLoadingTabsAccess = false;
  public isLearningExperienceDirectAccess = false;

  readonly canAccessParticipants = APP_CONST.ALLOWED_ROLES_FOR_USER_MANAGEMENT.some(role => role === this.currentUserRole);

  AppRoutes = APP_CONST.APP_ROUTES;

  get programsTabText(): string {
    return this.screenText.getScreenText('ACCOUNT_DATA_TAB_PROGRAMS');
  }

  get usersTabText(): string {
    return this.screenText.getScreenText('ACCOUNT_DATA_TAB_TEAM');
  }

  get sitesTabText(): string {
    return this.screenText.getScreenText('ACCOUNT_DATA_TAB_SITES');
  }

  get participantsTabText(): string {
    return this.screenText.getScreenText('ACCOUNT_DATA_TAB_PARTICIPANTS');
  }
  get familyTabText(): string {
    return this.screenText.getScreenText('ACCOUNT_DATA_TAB_FAMILY');
  }

  get paymentsTabText(): string {
    return this.screenText.getScreenText('ACCOUNT_DATA_TAB_PAYMENTS');
  }

  async created() {
    this.isLoadingTabsAccess = true;


    const learningExperienceDirectAccessEnabled = await APP_UTILITIES.getFeatureFlag(
      APP_CONST.FEATURE_KEYS.learningExperienceDirectAccess
    );

    const { accountId } = APP_UTILITIES.coreids();

    if (accountId && learningExperienceDirectAccessEnabled) {
      const { data: { isDirectAccess = false } } = await getaccountbyid({ accountId });
      this.isLearningExperienceDirectAccess = isDirectAccess;
    }

    this.isLoadingTabsAccess = false;
  }

  loadNav() {
    this.$emit('loadNav', {});
  }
}
