var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"border-bottom uk-tab-left horizontal-tabs"},[(!_vm.isLoadingTabsAccess)?_c('ul',{attrs:{"uk-tab":""}},[(!_vm.isLearningExperienceDirectAccess)?[_c('li',[_c('router-link',{staticClass:"uk-active",attrs:{"to":{ path: _vm.AppRoutes.ACCOUNT_PROGRAMS.path }}},[_vm._v(" "+_vm._s(_vm.programsTabText)+" ")])],1),_c('li',[_c('router-link',{class:[
              _vm.$route && _vm.$route.path === _vm.AppRoutes.ACCOUNT_SITES.path
                ? 'uk-active router-link-exact-active router-link-active'
                : ''
            ],attrs:{"to":{ path: _vm.AppRoutes.ACCOUNT_SITES.path }}},[_vm._v(" "+_vm._s(_vm.sitesTabText)+" ")])],1)]:_vm._e(),_c('li',[_c('router-link',{class:[
            _vm.$route && _vm.$route.path === _vm.AppRoutes.ACCOUNT_TEAM.path
              ? 'uk-active router-link-exact-active router-link-active'
              : ''
          ],attrs:{"to":{ path: _vm.AppRoutes.ACCOUNT_TEAM.path }}},[_vm._v(" "+_vm._s(_vm.usersTabText)+" ")])],1),(!_vm.isLearningExperienceDirectAccess)?[(_vm.canAccessParticipants)?_c('li',[_c('router-link',{class:[
              _vm.$route && _vm.$route.path === _vm.AppRoutes.ACCOUNT_PARTICIPANTS.path
                ? 'uk-active router-link-exact-active router-link-active'
                : ''
            ],attrs:{"to":{ path: _vm.AppRoutes.ACCOUNT_PARTICIPANTS.path }}},[_vm._v(" "+_vm._s(_vm.participantsTabText)+" ")])],1):_vm._e(),(_vm.canAccessParticipants)?_c('li',[_c('router-link',{class:[
              _vm.$route && _vm.$route.path === _vm.AppRoutes.ACCOUNT_CONTACTS.path
                ? 'uk-active router-link-exact-active router-link-active'
                : ''
            ],attrs:{"to":{ path: _vm.AppRoutes.ACCOUNT_CONTACTS.path }}},[_vm._v(" "+_vm._s(_vm.familyTabText)+" ")])],1):_vm._e(),(_vm.canAccessPayments)?_c('li',[_c('router-link',{class:[
              _vm.$route && _vm.$route.path === _vm.AppRoutes.ACCOUNT_PAYMENTS.path
                ? 'uk-active router-link-exact-active router-link-active'
                : ''
            ],attrs:{"to":{ path: _vm.AppRoutes.ACCOUNT_PAYMENTS.path }}},[_vm._v(" "+_vm._s(_vm.paymentsTabText)+" ")])],1):_vm._e()]:_vm._e()],2):_vm._e()]),_c('router-view',{on:{"loadNav":function($event){return _vm.loadNav()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }